:root {
  --body: #444957;
  --button: #3ba046;
  --h-1-font-family: "SourceSans Pro-Bold", Helvetica;
  --h-1-font-size: 90px;
  --h-1-font-style: normal;
  --h-1-font-weight: 700;
  --h-1-letter-spacing: 0px;
  --h-1-line-height: 100px;
  --h-2-font-family: "Poppins-SemiBold", Helvetica;
  --h-2-font-size: 65px;
  --h-2-font-style: normal;
  --h-2-font-weight: 600;
  --h-2-letter-spacing: 0px;
  --h-2-line-height: 39.7233px;
  --p-1-font-family: "OpenSans-Regular", Helvetica;
  --p-1-font-size: 24px;
  --p-1-font-style: normal;
  --p-1-font-weight: 400;
  --p-1-letter-spacing: 0px;
  --p-1-line-height: 40px;
  --p-2-font-family: "OpenSans-Regular", Helvetica;
  --p-2-font-size: 22px;
  --p-2-font-style: normal;
  --p-2-font-weight: 400;
  --p-2-letter-spacing: 0px;
  --p-2-line-height: 40px;
  --primary: #0e2368;
}

.explore-now {
  background-color: #e23744;
  border-radius: 34px;
  align-items: flex-start;
  gap: 7px;
  width: 190px;
  height: 63px;
  padding: 14px 34px;
  display: flex;
  position: relative;
}

.explore-now .text-wrapper {
  color: #fff;
  letter-spacing: .18px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Open Sans, Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  position: relative;
}

.index {
  background-color: #0000;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.index .div {
  width: 1440px;
  height: 3527px;
  position: relative;
  overflow: hidden;
}

.index .overlap-group {
  width: 1440px;
  height: 2451px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .rectangle {
  width: 1440px;
  height: 804px;
  position: absolute;
  top: 0;
  left: 0;
}

.index .screenshot {
  width: 107px;
  height: 83px;
  position: absolute;
  top: 33px;
  left: 100px;
}

.index .img {
  width: 1440px;
  height: 653px;
  position: absolute;
  top: 803px;
  left: 0;
}

.index .rectangle-2 {
  width: 1440px;
  height: 467px;
  position: absolute;
  top: 989px;
  left: 0;
}

.index .group {
  width: 735px;
  height: 804px;
  position: absolute;
  top: 5px;
  left: 705px;
}

.index .explore-now-instance {
  position: absolute !important;
  top: 556px !important;
  left: 97px !important;
}

.index .discover-the-best {
  width: 333px;
  height: 184px;
  position: absolute;
  top: 239px;
  left: 103px;
}

.index .naturally-made {
  width: 332px;
  height: 44px;
  position: absolute;
  top: 467px;
  left: 101px;
}

.index .about-girl-lp {
  width: 384px;
  height: 468px;
  position: absolute;
  top: 989px;
  left: 205px;
}

.index .group-2 {
  background-color: #fff;
  width: 1440px;
  height: 995px;
  position: absolute;
  top: 1456px;
  left: 0;
}

.index .vector {
  width: 752px;
  height: 839px;
  position: absolute;
  top: 0;
  left: 688px;
}

.index .group-3 {
  width: 122px;
  height: 42px;
  position: absolute;
  top: 32px;
  left: 1277px;
}

.index .group-4 {
  width: 1225px;
  height: 679px;
  position: absolute;
  top: 1614px;
  left: 108px;
}

.index .frame {
  width: 447px;
  height: 259px;
  position: absolute;
  top: 1092px;
  left: 789px;
}

.index .element {
  width: 27px;
  height: 19px;
  position: absolute;
  top: 2363px;
  left: 707px;
}

.index .group-5 {
  width: 28px;
  height: 29px;
  position: absolute;
  top: 2357px;
  left: 663px;
}

.index .group-6 {
  width: 28px;
  height: 29px;
  position: absolute;
  top: 2357px;
  left: 749px;
}

.index .overlap {
  background-image: url("https://cdn.animaapp.com/projects/652efa67c6eb9f99ee78d37c/releases/652efaa9ddd60795451a24e9/img/group-296.png");
  background-size: 100% 100%;
  width: 1440px;
  height: 362px;
  position: absolute;
  top: 2451px;
  left: 1px;
}

.index .screenshot-2 {
  width: 161px;
  height: 125px;
  position: absolute;
  top: 123px;
  left: 103px;
}

.index .overlap-2 {
  background-color: #fff;
  width: 1440px;
  height: 994px;
  position: absolute;
  top: 1457px;
  left: 1440px;
}

.index .element-2 {
  width: 28px;
  height: 19px;
  position: absolute;
  top: 907px;
  left: 706px;
}

.index .group-7 {
  width: 28px;
  height: 29px;
  position: absolute;
  top: 901px;
  left: 663px;
}

.index .group-8 {
  width: 28px;
  height: 29px;
  position: absolute;
  top: 901px;
  left: 749px;
}

.index .group-9 {
  width: 1225px;
  height: 679px;
  position: absolute;
  top: 158px;
  left: 114px;
}

/*# sourceMappingURL=index.e236ad4b.css.map */
